import { GITHUB_FLAGS } from './index.server'

import { SSRSafeEnv } from '~/utils/ssr.utils'

// https://github.com/atomantic/is-ua-webview/blob/main/data/rules.js#L1C18-L11C2
export const webviewRules = [
  // if it says it's a webview, let's go with that
  'WebView',
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  'Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})',
  // old chrome android webview agent
  'Linux; U; Android',
]

export const isCloudDeploy = () => {
  const stage = SSRSafeEnv.server?.STAGE ?? SSRSafeEnv.client?.stage
  return stage !== 'prod' && stage !== 'beta'
}

export const isPullRequestDeploy = () => {
  const stage = SSRSafeEnv.server?.STAGE ?? SSRSafeEnv.client?.stage
  return stage?.includes('pr-')
}

/**
 * Utility to adapt the signature loading time based on the stage.
 *
 * @param stage the current environement stage
 * @returns the time in seconds to wait signature
 */
export const getPreparingSignatureWaitingTime = () => {
  const stage = SSRSafeEnv.server?.STAGE ?? SSRSafeEnv.client?.stage

  if (stage === 'dev') return 5

  return stage === 'prod' ? 12 : 25
}

/**
 * Utility to get the docusign script src based on the stage.
 *
 * @returns the docusign script src based on the stage
 */
export const getDocusignScriptSrc = () => {
  const stage = SSRSafeEnv.server?.STAGE ?? SSRSafeEnv.client?.stage

  return `https://${
    stage === 'prod' ? 'na2' : 'demo'
  }.docusign.net/clickapi/sdk/latest/docusign-click.js`
}

/**
 * Utility to get the document detector asset src based on the flag value.
 *
 * To add new assets, get the source on:
 *  - https://docs.caf.io/sdks/web-javascript/getting-started (click on the "version" to open the source)
 *
 */
export const getDocumentDetectorAssetSrc = () => {
  const flagValue = GITHUB_FLAGS.DOCUMENT_DETECTOR_VERSION ?? ''
  const fallbackVersion = '6.2.0'

  const versionMap = {
    '6.2.0': '/assets/document-detector/document-detector-6.2.0.umd.js',
    '6.5.2': '/assets/document-detector/document-detector-6.5.2.umd.js',
  }

  return (
    versionMap[flagValue as keyof typeof versionMap] ??
    versionMap[fallbackVersion]
  )
}
